"use client";

import { useSession, SessionContext } from "../hooks/useSession";
import { Container } from "react-bootstrap";

export default function SessionLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const sessionContext = useSession({});

  return (
    <SessionContext.Provider value={sessionContext}>
      <Container fluid="sm">{children}</Container>
    </SessionContext.Provider>
  );
}
